
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LogoDark from '../../assets/images/6-Transparent-SolarWholesale-horiz.png';
// import LogoWhite from '../../assets/images/8-Transparent-SolarWholesale-horiz-ForDarkBG.png';
import styles from './styles';

const useStyles = makeStyles(styles);

const Navbar: React.FC = function() {
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="transparent">
      <Paper square elevation={0}>
        <Container>
          <Toolbar disableGutters>
            <img src={LogoDark} alt="Solar wholesale logo" className={classes.logo} />
          </Toolbar>
        </Container>
      </Paper>
    </AppBar>
  );
}

export default Navbar;
