
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NewBidPage from './new-bid-page/NewBidPage';
import MainLayout from './MainLayout';

const MainRouter: React.FC = function() {
  return (
    <BrowserRouter>
      <MainLayout>
        <Switch>
          <Route component={NewBidPage} />
        </Switch>
      </MainLayout>
    </BrowserRouter>
  );
};

export default MainRouter;
