
import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ChipInput from 'material-ui-chip-input';
import WysiwygEditor from '../wysiwyg-editor/WysiwygEditor';
import styles from './styles';

type EmailFormProps = {
  emails?: string[];
  subject?: string;
  message?: string;
  attachments?: string[];
  onSubmit?: (data: any)=>void;
  disabled?: boolean;
};

const useStyles = makeStyles(styles);

const EmailForm: React.FC<EmailFormProps> = function(props) {
  const {
    attachments = [],
    disabled = false,
    onSubmit = ()=>null
  } = props;

  const classes = useStyles();
  const [emails, setEmails] = useState(props.emails || []);
  const [subject, setSubject] = useState(props.subject || '');
  const [message, setMessage] = useState(props.message || '');

  const handleSubmit = useCallback((ev)=>{
    ev.preventDefault();
    onSubmit({emails, subject, message, attachments});

  }, [emails, message, onSubmit, attachments, subject]);

  const handleShouldEmailAdd = useCallback((email)=>{
    return true;
  }, []);

  const handleEmailAdd = useCallback((email)=>{
    setEmails(emails=>[...emails, email]);
  }, []);

  const handleEmailDelete = useCallback((email)=>{
    const index = emails.indexOf(email);
    emails.splice(index, 1);
    setEmails([...emails]);
  }, [emails]);

  const handleMessageChange = useCallback((message)=>{
    setMessage(message);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography gutterBottom>Email(s)</Typography>
          <ChipInput
            fullWidth
            variant="outlined"
            blurBehavior="add"
            value={emails}
            onBeforeAdd={handleShouldEmailAdd}
            onAdd={handleEmailAdd}
            onDelete={handleEmailDelete}
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <Typography gutterBottom>Subject</Typography>
          <TextField
            fullWidth
            value={subject}
            onChange={ev=>setSubject(ev.target.value)}
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <Typography gutterBottom>Message</Typography>
          <WysiwygEditor
            contentToEdit={props.message || ''} // Do not pass message variable directly here
            onChange={handleMessageChange}
            disabled={disabled}
            EditorProps={{
              editorClassName: classes.wysiwygEditor
            }}
          />
        </Grid>
        {/* <Grid item>
          <Typography gutterBottom>Message</Typography>
          <TextField
            fullWidth
            multiline
            rows={10}
            value={message}
            onChange={ev=>setMessage(ev.target.value)}
            disabled={disabled}
          />
        </Grid> */}
        <Grid item>
          <Typography gutterBottom>Attachments</Typography>
          {attachments.map((att, i)=>(
            <Link key={att} target="_BLANK" href={att}>Attachment {i+1}</Link>
          ))}
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={disabled}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EmailForm;
