import * as Yup from "yup";
import phone from "phone";

// TODO: Move declarations somewhere away
declare module "yup" {
  interface StringSchema {
    validatePhone(country: string): StringSchema;
  }
}

Yup.addMethod(Yup.string, "validatePhone", function (country: string) {
  return Yup.string()
    .required("Required.")
    .test("is-us-phone", "Invalid phone number.", function (value) {
      const parsed = phone(value ?? "", country);
      return !!parsed.length;
    });
});

export default Yup.object({
  first_name: Yup.string().required("Required."),
  last_name: Yup.string().required("Required."),
  phone: Yup.string().validatePhone("USA"),
  email: Yup.string().email("Invalid email address.").required("Required."),
  salesRep: Yup.object().required("Please choose a representative."),
  isDealer: Yup.boolean().notRequired(),
  dealerName: Yup.string().when("isDealer", {
    is: true,
    then: Yup.string().required("Required."),
    otherwise: Yup.string().notRequired(),
  }),
  dealerPhone: Yup.string().when("isDealer", {
    is: true,
    then: Yup.string().validatePhone("USA"),
    otherwise: Yup.string().notRequired(),
  }),
  dealerEmail: Yup.string().when("isDealer", {
    is: true,
    then: Yup.string().email("Invalid email address.").required("Required."),
    otherwise: Yup.string().notRequired(),
  }),
  dealerCalendarLink: Yup.string().when("isDealer", {
    is: true,
    then: Yup.string().url("Invalid link").notRequired(),
    otherwise: Yup.string().notRequired(),
  }),
  dealerProfit: Yup.number().when("isDealer", {
    is: true,
    then: Yup.number()
      .min(0, "Cannot be less than zero.")
      .required("Required."),
    otherwise: Yup.number().notRequired(),
  }),
  dealerProfitType: Yup.string().when("isDealer", {
    is: true,
    then: Yup.string()
      .oneOf(["fixed", "ppw", "ppp", "ppi"])
      .required("Required."),
    otherwise: Yup.string().notRequired(),
  }),
  address: Yup.object().required("Address is required."),
  roof_direction: Yup.string().required("Required."),
  utility_provider: Yup.string().notRequired(),
  offset: Yup.number().required("Required."),
  financing_type: Yup.string().required("Required."),
  discounts: Yup.array<object>(),
  kit_type: Yup.string().required("Required."),
  roof_type: Yup.string().required("Required."),
  panel_type: Yup.string().required("Required."),
  panel_adjustment: Yup.number().required("Required."),
  inverter_type: Yup.string().required("Required."),
  // inverter_type: Yup.string().when('kit_type', {
  //   is: 'grid-tied-hybrid',
  //   then: Yup.string().oneOf(['solark'], 'Required.'),
  //   otherwise: Yup.string().when('kit_type', {
  //     is: 'off-grid',
  //     then: Yup.string().oneOf(['solark'], 'Required'),
  //     otherwise: Yup.string().required('Required.')
  //   })
  // }),
  add_batteries: Yup.boolean(),
  battery_types: Yup.array<string>().when("add_batteries", {
    is: true,
    then: Yup.array<string>().ensure().required("Choose a battery to add."),
    otherwise: Yup.array().notRequired(),
  }),
  system_size_type: Yup.string().required(),
  average_bill: Yup.object().when("system_size_type", {
    is: "usage",
    then: Yup.object({
      usage: Yup.number()
        .moreThan(0, "Cannot be zero(0).")
        .required("Required."),
      payment: Yup.number()
        .moreThan(0, "Cannot be zero(0).")
        .required("Required."),
    }),
    otherwise: Yup.object().notRequired(),
  }),
  system_size: Yup.object().when("system_size_type", {
    is: "known",
    then: Yup.object({
      key: Yup.string().oneOf(["panels", "kw"]),
      value: Yup.number().moreThan(0, "Cannot be zero.").required("Required."),
    }),
    otherwise: Yup.object().notRequired(),
  }),
  sq_footage: Yup.number().when("system_size_type", {
    is: "sqfootage",
    then: Yup.number().required("Required.").moreThan(0, "Cannot be zero(0)."),
    otherwise: Yup.number().notRequired(),
  }),
});
