
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  wysiwygEditor: {
    minHeight: theme.spacing(30),
    borderBottom: `1px solid ${theme.palette.grey['400']}`
  }
});

export default styles;
