
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    textAlign: 'center'
  },
  stop: ({disabled}: any)=>({
    stroke: disabled ? theme.palette.grey['500'] : theme.palette.common.black,
    strokeWidth: 2,
    '&.active': {
      stroke: disabled ? theme.palette.grey['300'] : theme.palette.primary.main,
      strokeWidth: 4
    }
  }),
  anchorPoint: ({disabled}: any)=>({
    fill: disabled ? theme.palette.grey['500'] : theme.palette.common.black,
    '&:hover': {
      cursor: 'pointer',
      stroke: disabled ? theme.palette.grey['500'] : theme.palette.common.black,
      strokeWidth: 4
    },
    '&.active': {
      fill: 'transparent',
      stroke: disabled ? theme.palette.grey['300'] : theme.palette.primary.main,
      strokeWidth: 4,
      '&:hover': {
        stroke: disabled ? theme.palette.grey['300'] : theme.palette.primary.main
      }
    }
  }),
  label: ({disabled}: any)=>({
    fontWeight: 700,
    '&.active': {
      fill: disabled ? theme.palette.grey['300'] : theme.palette.primary.main
    }
  }),
  needle: ({disabled}: any)=>({
    fill: disabled ? theme.palette.grey['300'] : theme.palette.primary.main
  })
});

export default styles;