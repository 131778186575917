
import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MainRouter from './containers/MainRouter';
import mainTheme from './themes/main-theme';

function App() {
  return (
    <MuiThemeProvider theme={mainTheme}>
      <CssBaseline />
      <MainRouter />
    </MuiThemeProvider>
  );
}

export default App;
