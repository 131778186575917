
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  sectionLabel: {
    marginBottom: theme.spacing(4),
    fontWeight: 700
  },
  actionsContainer: {
    marginTop: theme.spacing(5)
  },
  submitBtn: {
    textTransform: 'none'
  }
});

export default styles;
