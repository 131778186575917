
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { sendMail, getData, generateBidPdf } from '../../services/api';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import BidForm, { BidFormData, BidFormRef } from '../../components/bid-form/BidForm';
import EmailForm from '../../components/email-form/EmailForm';
import emailMessageTemplate from '../../templates/solar-bid-email';

const NewBidPage: React.FC = function() {
  const bidForm = useRef<BidFormRef>(null);

  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState<any>(null);
  const [bidFormData, setBidFormData] = useState<BidFormData | null>(null);
  const [pdfUri, setPdfUri] = useState<string>('');
  const [openEmailDialog, setOpenEmailDialog] = useState(false);

  useEffect(()=>{
    getData()
      .then((data: any)=>{
        setRawData(data);
        setLoading(false);
      })
      .catch(err=>{
        console.log(err);
        alert(err.message);
      })
    ;
  }, []);

  const handleBidFormSubmit = useCallback(async (inputData)=>{
    try{
      setLoading(true);
      setBidFormData(inputData);
      const pdfUri = await generateBidPdf(inputData);
      console.log(pdfUri);
      setPdfUri(pdfUri);
    }
    catch(err){
      console.log(err);
      alert(err.message);
    }
    finally{
      setLoading(false);
    }
  }, []);

  const handleStartOver = useCallback(()=>{
    if(bidForm.current){
      setBidFormData(null);
      setPdfUri('');
      bidForm.current.reStart(true);
    }
  }, []);

  const handleGoBack = useCallback(()=>{
    if(bidForm.current){
      setBidFormData(null);
      setPdfUri('');
      bidForm.current.reStart(false);
    }
  }, []);

  const handleEmailSend = useCallback((emailData)=>{
    setOpenEmailDialog(false);
    setLoading(true);

    emailData.replyTo = bidFormData?.salesRep.email;
    sendMail(emailData)
      .then(()=>{
        alert('Email sent!');
        setLoading(false);
      })
      .catch(err=>{
        console.log(err);
        alert(err.message);
      })
    ;
  }, [bidFormData]);

  // Flags
  const generatedPdf = !!pdfUri;

  return (
    <>
      <Box pt={5} pb={20}>
        <Container>
          <Paper elevation={1}>
            {loading && <LinearProgress color="secondary" />}
            <div
              style={{
                padding: '80px 16px 160px',
                backgroundColor: '#f4daa7',
                backgroundImage: 'url(https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/backgrounds/sunflowers.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: '0 20%',
                filter: loading ? 'grayscale(100%)' : 'none'
              }}
            >
              <Typography variant="h3" align="center" gutterBottom>New bid</Typography>
            </div>
            <Box p={2} mt={5}>
              <Box display={!bidFormData ? 'block' : 'none'}>
                {rawData !== null && (
                  <BidForm
                    ref={bidForm}
                    roofs={rawData['roofs']}
                    panels={rawData['panels']}
                    inverters={rawData['inverters']}
                    batteries={rawData['batteries']}
                    financing={rawData['financing']}
                    discounts={rawData['discounts']}
                    employees={rawData['employees']}
                    onSubmit={handleBidFormSubmit}
                    disabled={loading}
                    viewOnly={generatedPdf}
                  />
                )}
              </Box>
              {generatedPdf && (
                <>
                  {!!pdfUri && <iframe title="Solar bid document" height={650} style={{display: 'block', width: '100%'}} src={pdfUri} />}
                  <div style={{ marginBottom: 16 }} />
                  <Grid container spacing={1} justify="center">
                    {/* <Grid item>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={()=>setOpenEmailDialog(true)}
                        disabled={loading}
                      >
                        Download
                      </Button>
                    </Grid> */}
                    <Grid item>
                      <Button
                        size="large"
                        variant="text"
                        disabled={loading}
                        onClick={handleStartOver}
                      >
                        Start Over
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="large"
                        variant="outlined"
                        disabled={loading}
                        onClick={handleGoBack}
                      >
                        Go Back
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        onClick={()=>setOpenEmailDialog(true)}
                        disabled={loading}
                      >
                        Send over email
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}

              {loading && (
                <Typography component="div" align="center">
                  <CircularProgress color="secondary" />
                </Typography>
              )}
            </Box>
          </Paper>
        </Container>
      </Box>

      {generatedPdf && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openEmailDialog}
          onClose={()=>setOpenEmailDialog(false)}
        >
          <DialogTitle>Send email</DialogTitle>
          <DialogContent>
            <EmailForm
              emails={bidFormData ? [bidFormData.email as string] : undefined}
              subject="Your solar bid"
              message={emailMessageTemplate(bidFormData)}
              attachments={pdfUri ? [pdfUri as string] : undefined}
              disabled={loading}
              onSubmit={handleEmailSend}
            />
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </>
  );
};

export default NewBidPage;
