import React, { useState, useEffect, useRef } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  Modifier,
  SelectionState,
} from "draft-js";
import { ContentBlock, Editor, EditorProps } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const dynamicValueRegExp = /\$\{\w+(\.\w+)?\}/gi;

// Button
const DynamicValueButton = ({ editorState, onChange, fieldsMap }: any) => {
  const buttonEl = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = (valueKey: any) => (ev: any) => {
    ev.stopPropagation();

    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity(
      "dynamic-value",
      "IMMUTABLE",
      {}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newContentState = Modifier.replaceText(
      contentStateWithEntity,
      selectionState,
      "${" + valueKey + "}",
      undefined,
      entityKey
    );
    onChange(
      EditorState.push(editorState, newContentState, "insert-characters")
    );

    setMenuOpen(false);
  };

  return (
    <>
      <Button
        innerRef={buttonEl}
        onClick={() => setMenuOpen(true)}
        size="small"
        color="secondary"
      >
        Insert dynamic value
      </Button>
      <Popper
        anchorEl={buttonEl.current}
        open={menuOpen}
        style={{ zIndex: 99 }}
      >
        <Paper>
          <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
            <MenuList>
              {Object.keys(fieldsMap).map(key => (
                <MenuItem key={key} onClick={handleMenuClick(key)}>
                  {fieldsMap[key]}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

// Decoration component
const DecoratedDynamicValue: React.FC = ({ children }) => {
  return (
    <span
      style={{
        lineHeight: 1,
        padding: "2px 5px",
        backgroundColor: "#eeeeee",
        color: "red",
        borderBottom: "1px solid #cccccc",
        fontFamily: "monospace",
        fontSize: 12,
        boxShadow: "rgba(0,0,0,0.15) 2px 2px 3px",
      }}
    >
      {children}
    </span>
  );
};

// Decorators
const customDecorators = [
  {
    // Decorates ${dynamic_var}
    strategy: (contentBlock: any, callBack: any, contentState: any) => {
      const text = contentBlock.getText();
      let matchArr;

      while ((matchArr = dynamicValueRegExp.exec(text)) !== null) {
        callBack(matchArr.index, matchArr.index + matchArr[0].length);
      }
    },
    component: DecoratedDynamicValue,
  },
];

interface WysiWygEditorProps {
  onChange: (html: string) => void;
  contentToEdit?: string;
  EditorProps?: EditorProps;
  disabled?: boolean;
  dynamicFieldsMap?: any;
}

// Editor
const WysiwygEditor: React.FC<WysiWygEditorProps> = ({
  onChange,
  contentToEdit,
  EditorProps = {},
  disabled = false,
  dynamicFieldsMap,
}) => {
  const [editorState, setEditorState] = useState<EditorState>();

  const onEditorStateChange = (newState: any) => {
    setEditorState(newState);
  };

  useEffect(() => {
    if (editorState) {
      onChange(
        draftToHtml(
          convertToRaw(editorState.getCurrentContent() as ContentState),
          {},
          false,
          ({ type, data }) => {
            if (type === "IMAGE") {
              const alignment = data.alignment || "none";

              let marginProp;
              switch (alignment) {
                case "left":
                  marginProp = "margin-right";
                  break;
                case "right":
                  marginProp = "margin-left";
                  break;
                case "center":
                default:
                  marginProp = "margin";
              }

              return `<img src="${data.src}" alt="${data.alt}" style="display: block; height: ${data.height}; width: ${data.width}; ${marginProp}: auto;"/>`;
            }
          }
        )
      );
    }
  }, [editorState, onChange]);

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(
      contentToEdit || "",
      (nodeName, node) => {
        if (nodeName === "img") {
          let alignment;
          if (node.style.margin === "auto") alignment = "none";
          else if (node.style.marginLeft === "auto") alignment = "right";
          else alignment = "left";

          return {
            type: "IMAGE",
            mutability: "MUTABLE",
            data: {
              src: node.getAttribute("src"),
              height: node.style.height,
              width: node.style.width,
              alt: node.getAttribute("alt"),
              alignment: alignment,
            },
          };
        }
      }
    );

    const { contentBlocks, entityMap } = blocksFromHtml;
    let contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    if (dynamicFieldsMap) {
      // Apply entities to dynamic variables pattern matches
      let block: ContentBlock | undefined = contentState.getFirstBlock();

      do {
        const text = block.getText();
        let matchArr;

        while ((matchArr = dynamicValueRegExp.exec(text)) !== null) {
          const start = matchArr.index;
          const end = start + matchArr[0].length;
          const selection = SelectionState.createEmpty(block.getKey()).merge({
            anchorOffset: start,
            focusOffset: end,
          });
          const contentStateWithEntity = contentState.createEntity(
            "dynamic-value",
            "IMMUTABLE",
            {
              /** Can pass values here for decorator */
            }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          contentState = Modifier.applyEntity(
            contentState,
            selection as SelectionState,
            entityKey
          );
        }
      } while (!!(block = contentState.getBlockAfter(block.getKey())));
    }

    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  }, [contentToEdit, dynamicFieldsMap]);

  const getBase64 = (file: any, callback: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = error => {};
  };

  const uploadFile = (file: any) => {
    return new Promise((resolve, reject) => {
      getBase64(file, (data: any) => resolve({ data: { link: data } }));
    });
  };

  return (
    <div className="">
      <Editor
        {...EditorProps}
        readOnly={disabled || EditorProps.readOnly}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        customDecorators={dynamicFieldsMap && customDecorators}
        toolbarCustomButtons={
          dynamicFieldsMap && [
            <DynamicValueButton fieldsMap={dynamicFieldsMap} />,
          ]
        }
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "image",
            "remove",
            "history",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: {
            uploadCallback: uploadFile,
            alt: { present: true, mandatory: false },
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
          },
        }}
      />
    </div>
  );
};

export default WysiwygEditor;
