
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  geoSuggestContainer: {
    position: 'relative',
    width: '100%',
    fontSize: theme.typography.body1.fontSize,
    '&>div:first-child': {
      display: 'flex'
    }
  },
  geoSuggestInput: {
    font: 'inherit',
    color: 'currentColor',
    width: '100%',
    height: '1.1876em',
    margin: 0,
    display: 'block',
    padding: '18.5px 14px',
    minWidth: 0,
    boxSizing: 'content-box',

    background: theme.palette.common.white,
    border: (props: any)=>`1px solid ${props.error ? theme.palette.error.main : theme.palette.grey['400']}`,
    borderRadius: theme.spacing(0.5),
    transition: 'all 0.25s',
    '&:focus': {
      position: 'relative',
      zIndex: 11,
      borderColor: theme.palette.primary.main
    }
  },
  geoSuggests: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    background: theme.palette.common.white,
    margin: 0,
    padding: theme.spacing(1),
    listStyleType: 'none',
    boxShadow: theme.shadows[8],
    borderRadius: theme.spacing(0.5)
  },
  geoSuggestItem: {
    padding: theme.spacing(1.5),
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.grey['400']}`,
    '&:last-child': {
      borderBottom: 'none'
    },
    '&:hover': {
      background: theme.palette.grey['200']
    }
  },
  geoSuggestsHidden: {
    display: 'none'
  }
});

export default styles;
