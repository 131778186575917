
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  logo: {
    maxHeight: theme.spacing(8),
    margin: 'auto'
  }
});

export default styles;
