
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE as string;
const URL_SEND_MAIL = process.env.REACT_APP_SEND_MAIL_API as string;

export async function getData() {
  return await axios.get(API_BASE_URL + '/db/bidtool')
    .then(res => res.data);
};

// TODO: update objects with nested data
interface InputData {
  add_batteries: boolean,
  address: object,
  average_bill: object,
  battery_types?: object,
  dealerProfit: number,
  dealerProfitType: 'fixed' | 'ppw' | 'ppp' | 'ppi',
  discounts?: object,
  financing_type: string,
  inverter_type: string,
  isDealer: boolean,
  kit_type: 'grid-tied' | 'grid-tied-hybrid' | 'off-grid',
  offset: number,
  panel_type: string,
  panel_adjustment?: number,
  roof_type: string,
  roof_direction: 'n' | 'n-ne' | 'n-e' | 'e-ne' | 'e' | 'e-se' | 's-e' | 's-se' | 's' | 's-sw' | 's-w' | 'w-sw' | 'w' | 'w-nw' | 'n-w' | 'n-nw',
  sq_footage: number,
  system_size: object,
  system_size_type: 'usage' | 'sqfootage' | 'known',
};

async function postToApi(path: string, data: object) {
  try {
    const response = await axios.post(API_BASE_URL + path, data);
    return  response.data;
  }
  catch(err) {
    if(err.response)
      throw new Error(err.response.data.message);
    else
      throw err;
  }
}

export async function generateBidPdf(inputData: InputData) {
  // const bid = await solarCalcs(inputData);
  // const pdfInfo = await html2pdf({bid, user_input: inputData});
  // return pdfInfo.s3_path;
  return await postToApi('/bid/calculate-pdf', inputData);
}

export async function solarCalcs(inputData: InputData) {
  return await postToApi('/bid/calculate', inputData);
}

// export async function html2pdf(data: {bid: object, user_input: InputData}) {
//   return await postToApi('/bid/generate-pdf', data);
// }

export async function sendMail(data: any) {
  try{
    const replyTo = data.replyTo.trim();
    await axios.post(URL_SEND_MAIL, {
      email: replyTo ? [...data.emails, replyTo] : data.emails,
      reply_to: replyTo ? replyTo : undefined,
      subject: data.subject,
      body: data.message,
      attachment: data.attachments
    });
  }
  catch(err){
    if(err.response)
      throw new Error(err.response.data.message);
    else
      throw err;
  }
}
