
import React, { useCallback, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

export interface SystemSize {
  key: string;
  value: number;
}

export interface SystemSizeInputProps {
  error?: boolean;
  helperText?: any | {key: string; value: string;};
  value?: SystemSize;
  disabled?: boolean;
  wattagePerPanel: number;
  onChange?: (value: SystemSize)=>void;
  onFocus?: (e: any)=>void;
}

const SystemSizeInput: React.FC<SystemSizeInputProps> = function(props) {
  const {
    value = {key: 'panels', value: 0},
    wattagePerPanel,
    onChange = ()=>null,
    onFocus = ()=>null,
    disabled,
    error,
    helperText
  } = props;

  const [wattage, setWattage] = useState(value.value * wattagePerPanel);

  const handlePanelsChange = useCallback((ev: any)=>{
    const val = parseInt(ev.target.value) || 0;
    onChange({key: 'panels', value: Math.ceil(+val)});

  }, [onChange]);

  const handleWattageChange = useCallback((ev: any)=>{
    const val = parseFloat(ev.target.value) || 0;
    const panels = (+val/wattagePerPanel).toFixed(2);
    onChange({key: 'panels', value: Math.ceil(Number(panels))});

  }, [onChange, wattagePerPanel]);

  useEffect(()=>{
    setWattage(Number((value.value * wattagePerPanel).toFixed(2)));

  }, [value, wattagePerPanel]);

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm>
          <TextField
            label="# of panels"
            type="number"
            value={value.value}
            onChange={handlePanelsChange}
            fullWidth
            disabled={disabled}
            error={error}
            onFocus={onFocus}
          />
        </Grid>
        <Grid item xs="auto">
          <SyncAltIcon />
        </Grid>
        <Grid item xs={12} sm>
          <TextField
            label="Wattage (kW)"
            type="number"
            value={wattage}
            inputProps={{ step: wattagePerPanel }}
            onChange={(ev: any)=>setWattage(ev.target.value)}
            onBlur={handleWattageChange}
            disabled={disabled}
            fullWidth
            error={error}
            onFocus={onFocus}
          />
        </Grid>
      </Grid>
      <FormHelperText error={error}>{helperText?.value}</FormHelperText>
    </>
  );
};

export default SystemSizeInput;
