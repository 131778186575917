
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';

const mainTheme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: amber,
    background: {
      default: grey['100']
    }
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    h3: { fontWeight: 700 },
    h4: { fontWeight: 700 }
  },
  props: {
    MuiTextField: {
      variant: 'outlined'
    }
  }
});

export default mainTheme;
