
import React, { Fragment, useState, useEffect, useCallback, useImperativeHandle, forwardRef, useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Checkbox, FormLabel, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Slider from '@material-ui/core/Slider';
import { useFormik } from 'formik';
import PlaceSearchBar, { PlaceValueType } from '../place-search-bar/PlaceSearchBar';
import AverageBillInput, { AverageBill } from '../average-bill-input/AverageBillInput';
import DirectionInput from '../direction-input/DirectionInput';
import SystemSizeInput, { SystemSize } from '../system-size-input/SystemSizeInput';
import initialValues from './initial-values';
import validationSchema from './validation-schema';
import styles from './styles';

export type BidFormData = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  salesRep: any;
  isDealer?: boolean;
  dealerName?: string;
  dealerPhone?: string;
  dealerEmail?: string;
  dealerCalendarLink?: string;
  dealerProfit?: number;
  dealerProfitType?: string;
  address: PlaceValueType;
  roof_direction: string;
  utility_provider?: string;
  offset?: number;
  financing_type: string;
  discounts: {[key: string]: any}[];
  kit_type: string;
  roof_type: string;
  panel_type: string;
  panel_adjustment?: number;
  inverter_type: string;
  add_batteries?: boolean;
  battery_types?: string[];
  system_size_type?: string;
  system_size?: SystemSize;
  sq_footage?: number;
  average_bill?: AverageBill;
};

type MenuOption = {label: string; value: any; default?: boolean; availableStates?: string[];};

type BidFormProps = {
  roofs: any;
  panels: any;
  inverters: any;
  batteries: any;
  financing: any;
  discounts: any;
  employees: any[];
  onSubmit?: (data: BidFormData)=>void;
  disabled?: boolean;
  viewOnly?: boolean;
};

export type BidFormRef = {reStart: (clearValues?: boolean)=>void};

const roofDirectionOptions = [
  {value: 'e', label: 'E'},
  {value: 'e-se', label: ''},
  {value: 's-e', label: 'SE'},
  {value: 's-se', label: ''},
  {value: 's', label: 'S'},
  {value: 's-sw', label: ''},
  {value: 's-w', label: 'SW'},
  {value: 'w-sw', label: ''},
  {value: 'w', label: 'W'},
  {value: 'w-nw', label: ''},
  {value: 'n-w', label: 'NW'},
  {value: 'n-nw', label: ''},
  {value: 'n', label: 'N'},
  {value: 'n-ne', label: ''},
  {value: 'n-e', label: 'NE'},
  {value: 'e-ne', label: ''}
];

const useStyles = makeStyles(styles);

const BidForm: React.ForwardRefRenderFunction<BidFormRef, BidFormProps> = function(props, ref) {
  const {
    roofs,
    panels,
    inverters,
    batteries,
    financing,
    discounts,
    employees = [],
    onSubmit = ()=>{},
    disabled = false

  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const calculatedInitialValues = useMemo(()=>{
    let defaultValues: any = {};
    defaultValues.roof_type = Object.keys(roofs).find(r=>!!roofs[r].default) || '';
    defaultValues.panel_type = Object.keys(panels).find(p=>!!panels[p].default) || '';
    defaultValues.inverter_type = Object.keys(inverters).find(i=>!!inverters[i].default) || '';
    defaultValues.financing_type = (Object.keys(financing).reduce((options, f: any)=>{
      return options.concat(Object.keys(financing[f].options).map(opt=>({val: opt, default: financing[f].options[opt].default})) as any)
    }, [] as any).find((opt: any)=>!!opt.default) || {}).val || '';

    return {...initialValues, ...defaultValues} as BidFormData;

  }, [roofs, panels, inverters, financing]);

  const formik = useFormik<BidFormData>({
    initialValues: calculatedInitialValues,
    validationSchema,
    onSubmit
  });

  const [showAdvancedParameters, setShowAdvancedParameters] = useState(false);
  const [monthlyBills, setMonthlyBills] = useState<{[key: string]: any}>({});
  const [tempOffsetVal, setTempOffsetVal] = useState(formik.values['offset']);

  const {
    inverter_type,
    battery_types,
    kit_type,
    panel_type,
    financing_type,
    salesRep,
    address,
    isDealer
  } = formik.values;

  function highlight(e: React.FocusEvent<HTMLInputElement>): void {e.target.select()}

  const roofOptions = useMemo(()=>Object.keys(roofs).map((r: any)=>({ label: roofs[r].name, value: r })), [roofs]);
  const panelOptions = useMemo(()=>Object.keys(panels).map((p: any)=>({ label: panels[p].name, value: p })), [panels]);
  const inverterOptions = useMemo(()=>{
    const allOptions = Object.keys(inverters).map((i: any)=>({ label: inverters[i].name, value: i }));
    let filteredOptions = allOptions;
    if(kit_type === 'grid-tied-hybrid' || kit_type === 'off-grid')
      filteredOptions = filteredOptions.filter(opt=>inverters[opt.value].batteryCompatible === true);
    if(panel_type === 'solaria370')
      filteredOptions = filteredOptions.filter(opt=>opt.value !== 'apSystemsQS1');

    return filteredOptions;

  }, [inverters, kit_type, panel_type]);

  const financingOptions = useMemo(()=>{
    return Object.keys(financing).reduce((opts, f: any)=>{
      if(isDealer && !!financing[f].availableTo && financing[f].availableTo.indexOf('dealers') === -1)
        return opts;
      
      if(!!address && !!address.state && !!financing[f].availableStates && !financing[f].availableStates.find((st: any)=>st.toLowerCase() === address.state.toLowerCase()))
        return opts;

      return opts.concat(Object.keys(financing[f].options).map(opt=>{
        return {
          label: financing[f].options[opt].name,
          value: opt,
          availableTo: financing[f].availableTo,
          availableStates: financing[f].availableStates
        };
      }) as any)
    }, [] as any);

  }, [financing, address, isDealer]);

  const discountOptions = useMemo(()=>{
    return Object.keys(discounts).reduce((opts, d: any)=>{
      if(discounts[d].active)
        opts.push({
          label: discounts[d].name,
          value: discounts[d]
        });

      return opts;

    }, [] as any);

  }, [discounts]);

  const employeeOptions = useMemo(()=>{
    return employees.map((e: any)=>{
      const menuOption = e.active && e.roles.includes('sales') && {
        label: `${e.firstName} ${e.lastName}`,
        value: e
      };

      if(menuOption)
        return menuOption;

      return undefined;
    }).filter((opt)=>!!opt) as MenuOption[];

  }, [employees]);

  const batteryOptions = useMemo(()=>{
    const options: MenuOption[] = [];
    Object.keys(batteries).forEach(b=>{
      if(batteries[b].inverters.indexOf(inverter_type) >= 0)
        options.push({ label: batteries[b].name, value: b });
    });

    return options;
  }, [inverter_type, batteries]);

  const canAddBatteries = useMemo(()=>{
    const inverter = inverters[inverter_type];
    if(inverter && inverter.batteryCompatible)
      return true;

    return false;
  }, [inverter_type, inverters]);

  // Custom handlers/callbacks
  const handleAddBattery = useCallback(function(){
    const batteryTypes = formik.values['battery_types'] || [];
    formik.setFieldValue('battery_types', [...batteryTypes, batteryOptions[0].value], true);
  }, [formik, batteryOptions]);

  const handleRemoveBattery = useCallback(function(index){
    const batteryTypes = formik.values['battery_types'] || [];
    batteryTypes.splice(index, 1);
    formik.setFieldValue('battery_types', [...batteryTypes], true);
  }, [formik]);

  const handleBatteryChange = useCallback(function(index, newValue){
    const batteryTypes = formik.values['battery_types'] || [];
    batteryTypes[index] = newValue;
    formik.setFieldValue('battery_types', [...batteryTypes], true);
  }, [formik]);

  const handleAddressChange = useCallback(function(value){
    formik.setFieldValue('address', value, true);
    
  }, [formik]);

  const handleOffsetChange = useCallback(function(ev, newValue){
    setTempOffsetVal(newValue);
  }, []);

  const handleOffsetChangeCommit = useCallback(function(ev, newValue){
    formik.setFieldValue('offset', newValue, true);
  }, [formik]);

  const handleAvgBillChange = useCallback(function(value: AverageBill){
    formik.setFieldValue('average_bill', value, true);
  }, [formik]);

  const handleSystemSizeChange = useCallback(function(value: SystemSize){
    formik.setFieldValue('system_size', value, true);
  }, [formik])

  const handleRoofDirectionChange = useCallback(function(value: string){
    formik.setFieldValue('roof_direction', value, true);
  }, [formik]);

  const handlePanelTypeChange = useCallback(function(ev: any){
    formik.setFieldValue('panel_type', ev.target.value, true);
    // formik.setFieldValue('system_size', { key: 'panels', value: 0 }, true);
    if(ev.target.value === 'solaria370' && formik.values['inverter_type'] === 'apSystemsQS1'){
      formik.setFieldValue('inverter_type', 'enphase', true);
    }
  }, [formik]);

  function handleKitTypeChange(ev: any) {
    formik.setFieldValue('kit_type', ev.target.value, true);
    if(ev.target.value !== 'grid-tied' && !inverters[formik.values['inverter_type']]?.batteryCompatible)
      formik.setFieldValue('inverter_type', '', true);
  }

  function toggleDiscount(discount: object) {
    const discounts = formik.values['discounts'];
    
    if(discounts.indexOf(discount) > -1)
      formik.setFieldValue('discounts', discounts.filter(d=>d!==discount), true);
    else
      formik.setFieldValue('discounts', [...discounts, discount], true);
  }

  useImperativeHandle(ref, ()=>({
    reStart: (clearValues)=>{
      if(clearValues)
        formik.resetForm();
    }
  }), [formik]);

  // This effect updates financing type.
  useEffect(()=>{
    if(financing_type && !financingOptions.find((opt: any)=>opt.value === financing_type)){
      if(financingOptions.length === 1)
        formik.setFieldValue('financing_type', financingOptions[0].value, true);
      else
        formik.setFieldValue('financing_type', '', true);
    }

  }, [formik, financingOptions, financing_type]);

  // This effect updates the batteries field based on selected inverter.
  useEffect(()=>{
    if(!battery_types || !battery_types.length || canAddBatteries)
      return;
    else{
      formik.setFieldValue('add_batteries', false, true);
      formik.setFieldValue('battery_types', [], true);
    }

  }, [formik, canAddBatteries, battery_types, inverter_type, batteries]);

  useEffect(()=>{
    if(!battery_types || !battery_types.length)
      return;
    else{
      let batteriesSupported = true;
      battery_types.forEach(type=>{
        if(type && batteries[type].inverters.indexOf(inverter_type) < 0)
          batteriesSupported = false;
      });

      if(!batteriesSupported){
        formik.setFieldValue('add_batteries', false, true);
        formik.setFieldValue('battery_types', [], true);
      }
    }

  }, [formik, battery_types, inverter_type, batteries]);

  useEffect(()=>{
    if(salesRep && !!salesRep.isDealer !== isDealer){
      formik.setFieldValue('isDealer', !!salesRep.isDealer, true);
      formik.setFieldValue('dealerName', !!salesRep.isDealer ? `${salesRep.firstName || ''} ${salesRep.lastName || ''}`.trim() : '', true);
      formik.setFieldValue('dealerEmail', !!salesRep.isDealer ? salesRep.email || '' : '', true);
      formik.setFieldValue('dealerPhone', !!salesRep.isDealer ? salesRep.phoneCell || '' : '', true);
      formik.setFieldValue('dealerCalendarLink', !!salesRep.isDealer ? salesRep.calendarLink || '' : '', true);

      if(!!salesRep.isDealer)
        formik.setFieldValue('discounts', [], true);
      else
        formik.setFieldValue('dealerProfit', 0, true);
    }
  }, [formik, salesRep, isDealer]);

  const customerInformationMarkup = (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="First name"
          {...formik.getFieldProps('first_name')}
          fullWidth
          disabled={disabled}
          error={!!(formik.touched['first_name'] && formik.errors['first_name'])}
          helperText={formik.touched['first_name'] && formik.errors['first_name']}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Last name"
          {...formik.getFieldProps('last_name')}
          fullWidth
          disabled={disabled}
          error={!!(formik.touched['last_name'] && formik.errors['last_name'])}
          helperText={formik.touched['last_name'] && formik.errors['last_name']}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={12} lg={6}>
        <TextField
          label="Email address"
          {...formik.getFieldProps('email')}
          fullWidth
          disabled={disabled}
          error={!!(formik.touched['email'] && formik.errors['email'])}
          helperText={formik.touched['email'] && formik.errors['email']}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={12} lg={6}>
        <TextField
          label="Phone number (US)"
          {...formik.getFieldProps('phone')}
          fullWidth
          disabled={disabled}
          error={!!(formik.touched['phone'] && formik.errors['phone'])}
          helperText={formik.touched['phone'] && formik.errors['phone']}
        />
      </Grid>
    </Grid>
  );

  const salesRepMarkup = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Sales Representative"
          name="salesRep"
          fullWidth
          value={formik.values['salesRep']}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={disabled}
          select
          error={!!(formik.touched['salesRep'] && formik.errors['salesRep'])}
          helperText={formik.touched['salesRep'] && formik.errors['salesRep']}
        >
          {employeeOptions.map(opt=>(
            <MenuItem key={opt.value.email} value={opt.value}>{opt.label}</MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );

  const dealerInformationMarkup = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Dealer name"
          {...formik.getFieldProps('dealerName')}
          error={!!(formik.touched['dealerName'] && formik.errors['dealerName'])}
          helperText={formik.touched['dealerName'] && formik.errors['dealerName']}
          disabled={disabled}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6} md={12}>
        <TextField
          label="Dealer email"
          {...formik.getFieldProps('dealerEmail')}
          error={!!(formik.touched['dealerEmail'] && formik.errors['dealerEmail'])}
          helperText={formik.touched['dealerEmail'] && formik.errors['dealerEmail']}
          disabled={disabled}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6} md={12}>
        <TextField
          label="Dealer phone (US)"
          {...formik.getFieldProps('dealerPhone')}
          error={!!(formik.touched['dealerPhone'] && formik.errors['dealerPhone'])}
          helperText={formik.touched['dealerPhone'] && formik.errors['dealerPhone']}
          disabled={disabled}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Dealer calendar link"
          {...formik.getFieldProps('dealerCalendarLink')}
          error={!!(formik.touched['dealerCalendarLink'] && formik.errors['dealerCalendarLink'])}
          helperText={formik.touched['dealerCalendarLink'] && formik.errors['dealerCalendarLink']}
          disabled={disabled}
          fullWidth
        />
      </Grid>
    </Grid>
  );

  const locationInformationMarkup = (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <PlaceSearchBar
          placeholder="Search address..."
          name="address"
          value={formik.values['address']}
          onChange={handleAddressChange}
          onBlur={formik.handleBlur}
          disabled={disabled}
          error={!!(formik.touched['address'] && formik.errors['address'])}
          helperText={formik.touched['address'] && formik.errors['address']}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" gutterBottom>Average roof direction</Typography>
        <DirectionInput
          size={isSmallScreen ? 380 : 500}
          options={roofDirectionOptions}
          name="roof_direction"
          value={formik.values['roof_direction']}
          onChange={handleRoofDirectionChange}
          onBlur={formik.handleBlur}
          disabled={disabled}
          error={!!(formik.touched['roof_direction'] && formik.errors['roof_direction'])}
          helperText={formik.touched['roof_direction'] && formik.errors['roof_direction']}
        />
      </Grid>
    </Grid>
  );

  const usageDetailsMarkup = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioGroup row {...formik.getFieldProps('system_size_type')}>
          <FormControlLabel
            label="Monthly average"
            value="usage"
            control={<Radio disabled={disabled} />}
          />
          <FormControlLabel
            label="Square footage"
            value="sqfootage"
            control={<Radio disabled={disabled} />}
          />
          <FormControlLabel
            label="Known size"
            value="known"
            control={<Radio disabled={disabled} />}
          />
        </RadioGroup>
      </Grid>
      {formik.values['system_size_type'] === 'usage' && (
        <Grid item xs={12}>
          <AverageBillInput
            value={formik.values['average_bill']}
            onChange={handleAvgBillChange}
            onBlur={formik.handleBlur}
            monthlyBills={monthlyBills}
            disabled={disabled}
            onMonthlyBillsChange={setMonthlyBills}
            error={!!(formik.touched['average_bill'] && formik.errors['average_bill'])}
            helperText={formik.touched['average_bill'] && formik.errors['average_bill']}
            onFocus={highlight}
          />
        </Grid>
      )}
      {formik.values['system_size_type'] === 'sqfootage' && (
        <Grid item xs={12}>
          <TextField
            label="Sq. footage"
            type="number"
            fullWidth
            disabled={disabled}
            {...formik.getFieldProps('sq_footage')}
            error={!!(formik.touched['sq_footage'] && formik.errors['sq_footage'])}
            helperText={formik.touched['sq_footage'] && formik.errors['sq_footage']}
            onFocus={highlight}
          />
        </Grid>
      )}
      {formik.values['system_size_type'] === 'known' && (
        <Grid item xs={12}>
          <SystemSizeInput
            wattagePerPanel={panels[formik.values['panel_type']].wattage}
            value={formik.values['system_size']}
            onChange={handleSystemSizeChange}
            disabled={disabled}
            // InputStepperProps={{
            //   stepSize: formik.values['system_size']?.key === 'panels' ? 1 : panels[formik.values['panel_type']].wattage
            // }}
            error={!!(formik.touched['system_size'] && formik.errors['system_size'])}
            helperText={formik.touched['system_size'] && formik.errors['system_size']}
            onFocus={highlight}
          />
        </Grid>
      )}
    </Grid>
  );

  const systemDetailsMarkup = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Utility provider"
              {...formik.getFieldProps('utility_provider')}
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['utility_provider'] && formik.errors['utility_provider'])}
              helperText={formik.touched['utility_provider'] && formik.errors['utility_provider']}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Roof/Installation type"
              {...formik.getFieldProps('roof_type')}
              select
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['roof_type'] && formik.errors['roof_type'])}
              helperText={formik.touched['roof_type'] && formik.errors['roof_type']}
            >
              {roofOptions.map(op=>(
                <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Kit type"
              {...formik.getFieldProps('kit_type')}
              onChange={handleKitTypeChange}
              select
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['kit_type'] && formik.errors['kit_type'])}
              helperText={formik.touched['kit_type'] && formik.errors['kit_type']}
            >
              <MenuItem value="grid-tied">Grid Tied</MenuItem>
              <MenuItem value="grid-tied-hybrid">Grid Tied Hybrid</MenuItem>
              <MenuItem value="off-grid">Off Grid</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Typography color="textSecondary">Utility offset: &nbsp; <b>{Math.round(Number(tempOffsetVal)*100)}%</b></Typography>
            <div><br/><br/></div>
            <Slider
              min={0}
              max={2}
              step={0.01}
              valueLabelFormat={(val)=><b>{Math.round(val*100)}</b>}
              valueLabelDisplay="auto"
              value={tempOffsetVal}
              disabled={disabled}
              onChange={handleOffsetChange}
              onChangeCommitted={handleOffsetChangeCommit}
            />

            <div><br/><br/></div>
            {/* <TextField
              label="Utility Offset"
              type="number"
              {...formik.getFieldProps('offset')}
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['offset'] && formik.errors['offset'])}
              helperText={formik.touched['offset'] && formik.errors['offset']}
            /> */}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Panel type"
              {...formik.getFieldProps('panel_type')}
              onChange={handlePanelTypeChange}
              select
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['panel_type'] && formik.errors['panel_type'])}
              helperText={formik.touched['panel_type'] && formik.errors['panel_type']}
            >
              {panelOptions.map(op=>(
                <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Inverter type"
              {...formik.getFieldProps('inverter_type')}
              select
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['inverter_type'] && formik.errors['inverter_type'])}
              helperText={formik.touched['inverter_type'] && formik.errors['inverter_type']}
            >
              {inverterOptions.map(op=>(
                <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
              ))}
            </TextField>
          </Grid>

          {canAddBatteries && (
            <Grid item xs={12}>
              <FormControlLabel
                label="Add batteries"
                name="add_batteries"
                checked={formik.values['add_batteries']}
                onChange={formik.handleChange}
                disabled={disabled}
                control={<Switch />}
              />
            </Grid>
          )}
          {canAddBatteries && formik.values['add_batteries'] && (
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                {formik.values['battery_types']?.map((battery_type, i)=>(
                  <Fragment key={''+i}>
                    <Grid item xs>
                      <TextField
                        label={`Battery ${i+1}`}
                        value={battery_type}
                        onChange={ev=>handleBatteryChange(i, ev.target.value)}
                        select
                        fullWidth
                        disabled={disabled}
                      >
                        {batteryOptions.map(op=>(
                          <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs="auto" zeroMinWidth>
                      <IconButton disabled={disabled} onClick={()=>handleRemoveBattery(i)}>
                        <ClearIcon color="error" />
                      </IconButton>
                    </Grid>
                  </Fragment>
                ))}

                <Grid item xs={12}>
                  {!!formik.errors['battery_types'] && formik.touched['battery_types'] && (
                    <Typography variant="caption" component="div" color="error" style={{ marginTop: 8 }}>{formik.errors['battery_types']}</Typography>
                  )}

                  <br />

                  <IconButton
                    disabled={disabled}
                    onClick={handleAddBattery}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControlLabel
              label="Advanced parameters"
              onChange={(ev: any)=>setShowAdvancedParameters(ev.target.checked)}
              checked={showAdvancedParameters}
              control={<Switch disabled={disabled} />}
            />
          </Grid>
          {showAdvancedParameters && (
            <Grid item xs={12} sm={6}>
              <TextField
                label="Panel adjustment"
                type="number"
                {...formik.getFieldProps('panel_adjustment')}
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['panel_adjustment'] && formik.errors['panel_adjustment'])}
                helperText={formik.touched['panel_adjustment'] && formik.errors['panel_adjustment']}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const financingDetailsMarkup = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div><br/></div>
        <TextField
          label="Financing type"
          {...formik.getFieldProps('financing_type')}
          select
          fullWidth
          disabled={disabled}
          error={!!(formik.touched['financing_type'] && formik.errors['financing_type'])}
          helperText={formik.touched['financing_type'] && formik.errors['financing_type']}
        >
          {financingOptions.map((op: any)=>(
            <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
          ))}
        </TextField>
      </Grid>

      {!isDealer && (
        <Grid item xs={12}>
          <div><br/></div>
          <FormLabel>Discounts</FormLabel>
          <div><br/></div>
          {discountOptions.map((op: any, i: number)=>(
            <FormControlLabel
              key={op.label + i + ''}
              label={op.label}
              checked={formik.values['discounts']?.indexOf(op.value) > -1}
              onChange={()=>toggleDiscount(op.value)}
              disabled={disabled}
              control={<Checkbox />}
            />
          ))}
        </Grid>
      )}

      {isDealer && (
        <>
          <Grid item xs={12}>
            <div><br/></div>
            <FormLabel>Dealer profit</FormLabel>
            <div><br/></div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Profit amount (USD)"
              type="number"
              {...formik.getFieldProps('dealerProfit')}
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['dealerProfit'] && formik.errors['dealerProfit'])}
              helperText={formik.touched['dealerProfit'] && formik.errors['dealerProfit']}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Profit type"
              {...formik.getFieldProps('dealerProfitType')}
              select
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['dealerProfitType'] && formik.errors['dealerProfitType'])}
              helperText={formik.touched['dealerProfitType'] && formik.errors['dealerProfitType']}
            >
              <MenuItem value="fixed">Fixed amount</MenuItem>
              <MenuItem value="ppw">Price per Watt</MenuItem>
              <MenuItem value="ppp">Price per panel</MenuItem>
              <MenuItem value="ppi">Price per inverter</MenuItem>
            </TextField>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={4} direction={isSmallScreen ? 'column-reverse' : 'row'}>
        <Grid item xs={12} md={8}>
          <Box mb={4}>
            <Typography
              className={classes.sectionLabel}
              variant="subtitle1"

            >(KW) Usage</Typography>
            
            { usageDetailsMarkup }
          </Box>
          <Box mb={4}>
            <Typography
              className={classes.sectionLabel}
              variant="subtitle1"

            >System details</Typography>
            
            { systemDetailsMarkup }
          </Box>

          <Box mb={4}>
            <Typography
              className={classes.sectionLabel}
              variant="subtitle1"

            >Location</Typography>
            
            { locationInformationMarkup }
          </Box>

          <Box mb={4}>
            <Typography
              className={classes.sectionLabel}
              variant="subtitle1"

            >Financing</Typography>
            
            { financingDetailsMarkup }
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <Box mb={4}>
            <Typography
              className={classes.sectionLabel}
              variant="subtitle1"

            >Customer information</Typography>

            { customerInformationMarkup }
          </Box>

          <Box mb={4}>
            <Typography
              className={classes.sectionLabel}
              variant="subtitle1"

            >Sales representative</Typography>
            
            { salesRepMarkup }
          </Box>

          {!!isDealer && (
            <Box mb={4}>
              <Typography
                className={classes.sectionLabel}
                variant="subtitle1"

              >Dealer information</Typography>

              { dealerInformationMarkup }
            </Box>
          )}
        </Grid>
      </Grid>

      <div className={classes.actionsContainer}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Button
              className={classes.submitBtn}
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              disabled={disabled}
            >
              Generate Bid
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default forwardRef(BidForm);
